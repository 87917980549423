import React, { useState, useEffect } from "react";
import { withStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import { appActions } from "../../../actions";
import DOMPurify from "dompurify";
import { DialogTitle, Divider, Typography } from "@mui/material";

const NoticeNotification = ({ classes, onClose }) => {
  const [policies, setPolicies] = useState([]);
  const app = useSelector(state => state.app);
  const renewPolicies = useSelector(state => state.renewPolicies);
  const dispatch = useDispatch();
  useEffect(() => {
    setPolicies(renewPolicies.pendingList);
    const shouldDisplayNotification = true;
    if (shouldDisplayNotification) {
    }
  }, []);

  const closeDialog = () => {
    onClose();
  };

  const renderDetails = () => {
    return (
      <Typography
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(`
           <p style="margin-bottom: 4px;"><b>NOTE:</b></p>
           <p style="margin-top: 4px;"><span className='${classes.typography}'> Regulatory updates for LIA CI Framework 2024 and MOM Enhancements to Foreign Worker Medical Insurance</span></p>
           <p>For proposals and renewals (including conversions) with coverage start date on 1 July 2025 and later, please use product summaries dated “July 2025”, which will be made available from 1 May 2025:</p>
          <ul >
            <li>PRUTreasures Flexi II Product Summary - Group Term Life (Jul 2025)</li>
            <li>PRUTreasures Flexi II Product Summary - Group Crisis Cover Accelerated (Jul 2025)</li>
            <li>PRUTreasures Flexi II Product Summary - Group Pre-Crisis Guard (Jul 2025)</li>
            <li>PRUTreasures Flexi II Product Summary - Group Foreign Worker Medical lnsurance (Jul 2025)</li>
          </ul>
          <p>For client who is taking up any of the above benefits, it is mandatory for financial representative to go through the above product summary with client.</p>
          <p>The new Product Summary can be downloaded from PruInfo and FC PruWorks (Documents Section).</p>
            </p>`)
        }}
      />
    );
  };

  return (
    <Dialog
      open={true}
      onClose={closeDialog}
      disableBackdropClick
      maxWidth={false}
      classes={{
        paper: classes.dialogPaper
      }}
    >
      <DialogTitle className={classes.dialogTitle}>Notice</DialogTitle>
      <DialogContent className={classes.dContent}>
        <>{renderDetails()}</>
        <Button onClick={closeDialog} className={classes.closeButtonFab}>
          <i className="material-icons">close</i>
        </Button>

        <DialogActions
          classes={{
            root: classes.dialogActions
          }}
        >
          <Button onClick={closeDialog} className={classes.closeButton} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const styles = theme => ({
  dialogTitle: {
    textAlign: "center",
    fontWeight: 600,
    backgroundColor: "#ffffff"
  },
  dContent: {
    backgroundColor: "#eeeeee"
  },
  dialogPaper: {
    maxWidth: 700
  },
  typography: {
    fontSize: "1rem",
    fontWeight: 600,
    padding: "30px 0px 0px 0px"
  },
  closeButton: {
    minWidth: 130,
    margin: "40px 0px 30px 0px"
  },
  dialogContent: {
    padding: "1rem"
  },
  closeButtonFab: {
    position: "fixed",
    top: 20,
    right: 20,
    [theme.breakpoints.down("sm")]: {
      opacity: 0.3,
      "&:hover": {
        opacity: 1
      }
    }
  },
  dialogActions: {
    justifyContent: "center"
  },
  renewalTable: {
    width: "100%",
    marginTop: "30px"
  },
  tableHead: {
    fontWeight: 500,
    textAlign: "left",
    fontSize: "1.2rem",
    color: "#7f7f7f",
    paddingBottom: "10px"
  }
});

export default withStyles(styles, { withTheme: true })(NoticeNotification);
